<template>
  <header>
    <div class="container px-0 h-100">
      <div class="d-flex h-100 align-items-center justify-content-between">
        <div class="logo">
          <router-link to="/">
            <img src="/media/brand/logo.png" alt="" />
          </router-link>
        </div>
        <div class="user-actions">
          <router-link :to="{ name: 'profile-account' }">
            <div class="user-symbol">
              {{ getUserSymbol }}
            </div>
          </router-link>
          <div class="logout-button">
            <img
              src="/media/layout/header/header-logout.svg"
              alt=""
              class="cursor-pointer"
              @click="onLogout">
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Header",
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    getUserSymbol() {
      return (
        'TS'
      );
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  }
};
</script>
